

































































































































































































































































































































































import Base from '@/mixins/Base.vue';
import { IClient } from '@/interfaces/client';
import { IInfringement, IInfringementsResponse } from '@/interfaces/infringement';
import { IPagination } from '@/interfaces/pagination';

const component = Base.extend({

    data() {
        return {
            infringementsParams: {
                status: null,
                name: '',
                sortBy: 'id',
                orderBy: 'desc',
                clients: [] as Array<IClient>,
                page: 1,
            },

            infringements: {
                data: [] as Array<IInfringement>,
                meta: {} as IPagination | any,
            } as IInfringementsResponse,
        };
    },
    mounted() {
        this.getInfringements();
    },
    methods: {
        getInfringements(page: number = 1): void {
            this.infringementsParams.page = page;

            this.get<IInfringementsResponse>('infringements', {
                params: {
                    ...this.infringementsParams,
                    clients: this.infringementsParams.clients.map((c) => c.id),

                },
            }).then(({ data }) => {
                this.infringements = data;
            });
        },
    },
});

export default component;
